<template>
  <b-form @submit.prevent="handlerSaveSupplier">
    <b-overlay
      :show="is_loading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
    >
      <div class="btns-actions">
        <b-button
          variant="primary"
          class="ml-auto"
          @click="handlerSaveSupplier"
        >
          Сохранить
        </b-button>
      </div>
      <div
        class="b-card"
        style="margin-top: 16px"
      >
        <div class="b-card__title">Основные данные</div>
        <b-row class="mb-4">
          <b-col cols="4">
            <label>ИНН</label>
            <b-form-input
              v-model="entity.inn"
              :class="{ error: inn_error }"
              :formatter="formatter_number"
              type="number"
              min-length="10"
              max-length="12"
              @keyup="searchEntity"
            />
            <template v-if="inn_search_result">
              <apollo-query
                :query="require('../gql/getEntityDadata.gql')"
                :variables="{ inn: entity.inn }"
                :skip="entity.inn < 10"
                fetch-policy="no-cache"
              >
                <template #default="{ result: { error, data }, isLoading }">
                  <div
                    v-if="isLoading"
                    class="b-search-result p-2 center"
                  >
                    <b-spinner variant="primary" />
                  </div>
                  <div
                    v-else-if="error"
                    class="error apollo"
                  >
                    Ошибка при запросе
                  </div>
                  <div
                    v-else-if="data"
                    class="b-search-result"
                  >
                    <div
                      v-for="(item, index) in data.Dadata"
                      :key="index"
                      class="item cursor"
                      @click="selectInn(item)"
                    >
                      <div class="item-name">{{ item.name }}</div>
                      <div class="item-info">
                        ИНН: {{ item.inn }} КПП: {{ item.kpp }} {{ item.address_entity.city }},
                        {{ item.address_entity.country }}, {{ item.address_entity.post_index }}, улица
                        {{ item.address_entity.street }},
                        {{ item.address_entity.apartment }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="b-search-result p-3 center"
                  >
                    Нет результата
                  </div>
                </template>
              </apollo-query>
            </template>
          </b-col>
          <b-col cols="4">
            <label>КПП</label>
            <b-form-input
              v-model="entity.kpp"
              :class="{ error: kpp_error }"
              :formatter="formatter_number"
              type="text"
              maxlength="9"
              required
            />
          </b-col>
          <b-col cols="4">
            <label>ОГРН</label>
            <b-form-input
              v-model="entity.ogrn"
              :class="{ error: ogrn_error }"
              type="number"
              pattern="[0-9]{13,15}"
            />
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Полное наименование</label>
            <b-form-input
              v-model="entity.full_name"
              type="text"
            />
          </b-col>
          <b-col cols="6">
            <label :class="{ error: name_error }">Краткое наименование</label>
            <b-form-input
              v-model="entity.name"
              :class="{ error: name_error }"
              type="text"
            />
          </b-col>
        </b-row>
      </div>

      <!-- Entity Addres -->
      <div class="b-card mt-4">
        <div class="b-card__title">Юридический адрес</div>
        <template v-if="!is_form_entity">
          <div
            class="btn-add cursor"
            @click="toggleForm('entity')"
          >
            <img
              src="/img/icons/contractors/plus.svg"
              class="mr-2"
              alt=""
            />
            Добавить адрес
          </div>
        </template>
        <template v-else>
          <b-row>
            <b-col cols="6">
              <label>Страна, город, улица, дом</label>
              <b-form-input
                v-model="address_entity"
                type="text"
                placeholder="Введите адрес"
                @keyup="(e) => searchAddress('entity', e)"
              />
              <template
                v-if="address_entity_search_result"
                class="search-address"
              >
                <apollo-query
                  :query="require('../gql/getAddressDadata.gql')"
                  :variables="{ value: address_entity }"
                  :skip="address_entity < 5"
                  fetch-policy="no-cache"
                >
                  <template #default="{ result: { error, data }, isLoading }">
                    <div
                      v-if="isLoading"
                      class="b-search-result p-2 center"
                    >
                      <b-spinner variant="primary" />
                    </div>
                    <div
                      v-else-if="error"
                      class="error apollo"
                    >
                      Ошибка при запросе
                    </div>
                    <div
                      v-else-if="data"
                      class="b-search-result"
                    >
                      <div
                        v-for="(item, index) in data.DadataAddress"
                        :key="index"
                        class="item cursor"
                        @click="setAddress(item)"
                      >
                        <div class="item-info street">
                          улица {{ item.street }}
                          {{ item.house ? `, ${item.house}` : '' }}
                        </div>
                        <div class="item-info">
                          {{ item.country }}, г. {{ item.city }}
                          <span class="post_index"> Индекс: {{ item.post_index }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="b-search-result p-3 center"
                    >
                      Нет результата
                    </div>
                  </template>
                </apollo-query>
              </template>
            </b-col>
            <b-col cols="2">
              <label>Квартира/Офис</label>
              <b-form-input
                v-model="entity.address_entity.apartment"
                type="text"
              />
            </b-col>
            <b-col cols="2">
              <label>Почтовый индекс</label>
              <b-form-input
                v-model="entity.address_entity.post_index"
                type="text"
                placeholder="000000"
              />
            </b-col>
            <b-col
              cols="2"
              class="d-flex justify-content-end b-remove-adress"
            >
              <div
                class="cursor"
                @click="removeAddress('entity')"
              >
                Удалить адрес
                <img
                  src="/img/icons/trash.svg"
                  alt="Удалить адрес"
                />
              </div>
            </b-col>
          </b-row>
        </template>
      </div>
      <!-- Fact Addres -->
      <div class="b-card mt-4">
        <div class="b-card__title">Физический адрес</div>
        <template v-if="!is_form_fact">
          <div
            class="btn-add cursor"
            @click="toggleForm('fact')"
          >
            <img
              src="/img/icons/contractors/plus.svg"
              class="mr-2"
              alt=""
            />
            Добавить адрес
          </div>
        </template>
        <template v-else>
          <b-row>
            <b-col cols="6">
              <label>Страна, город, улица, дом</label>
              <b-form-input
                v-model="address_fact"
                type="text"
                placeholder="Введите адрес"
                @keyup="(e) => searchAddress('fact', e)"
              />
              <template
                v-if="address_fact_search_result"
                class="search-address"
              >
                <apollo-query
                  :query="require('../gql/getAddressDadata.gql')"
                  :variables="{ value: address_fact }"
                  :skip="address_fact < 5"
                  fetch-policy="no-cache"
                >
                  <template #default="{ result: { error, data }, isLoading }">
                    <div
                      v-if="isLoading"
                      class="b-search-result p-2 center"
                    >
                      <b-spinner variant="primary" />
                    </div>
                    <div
                      v-else-if="error"
                      class="error apollo"
                    >
                      Ошибка при запросе
                    </div>
                    <div
                      v-else-if="data"
                      class="b-search-result"
                    >
                      <div
                        v-for="(item, index) in data.DadataAddress"
                        :key="index"
                        class="item cursor"
                        @click="setFactAddress(item)"
                      >
                        <div class="item-info street">
                          улица {{ item.street }}
                          {{ item.house ? `, ${item.house}` : '' }}
                        </div>
                        <div class="item-info">
                          {{ item.country }}, {{ item.city }}
                          <span class="post_index"> Индекс: {{ item.post_index }} </span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="b-search-result p-3 center"
                    >
                      Нет результата
                    </div>
                  </template>
                </apollo-query>
              </template>
            </b-col>
            <b-col cols="2">
              <label>Квартира/Офис</label>
              <b-form-input
                v-model="entity.address_fact.apartment"
                type="text"
              />
            </b-col>
            <b-col cols="2">
              <label>Почтовый индекс</label>
              <b-form-input
                v-model="entity.address_fact.post_index"
                type="text"
                placeholder="000000"
              />
            </b-col>
            <b-col
              cols="2"
              class="d-flex justify-content-end b-remove-adress"
            >
              <div
                class="cursor"
                @click="removeAddress('fact')"
              >
                Удалить адрес
                <img
                  src="/img/icons/trash.svg"
                  alt="Удалить адрес"
                />
              </div>
            </b-col>
          </b-row>
        </template>
      </div>
      <!-- Contacts -->
      <div class="b-card mt-4 mb-4">
        <div class="b-card__title">Контакты сотрудников</div>
        <template v-if="!is_form_contact">
          <b-row>
            <b-col>
              <div
                class="btn-add cursor"
                @click="toggleForm('contact')"
              >
                <img
                  src="/img/icons/contractors/plus.svg"
                  class="mr-2"
                  alt=""
                />
                Добавить контакт
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row class="mt-2 b-addcontact">
            <b-col cols="4">
              <label>ФИО</label>
              <b-form-input
                v-model="contact.name"
                type="text"
                placeholder="ФИО"
              />
              <div
                v-if="add_contact_error"
                class="mt-1 error"
              >
                * - Необходимо указать имя
              </div>
            </b-col>
            <b-col cols="4">
              <label>Номер телефона</label>
              <b-form-input
                v-model="contact.phone"
                type="text"
                placeholder="Введите номер телефона"
              />
            </b-col>
            <b-col cols="4">
              <label>E-mail</label>
              <b-form-input
                v-model="contact.email"
                type="email"
                placeholder="Введите email"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-form-checkbox
                v-model="contact.main"
                class="align-items-center d-flex"
              >
                <span class="">Это контактное лицо</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-4">
            <b-col>
              <b-button
                variant="primary"
                @click="add_contact"
              >
                Добавить контакт
              </b-button>
              <b-button
                class="ml-2"
                variant="outline-primary"
                @click="toggleForm('contact')"
              >
                Отменить
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template v-if="entity.contacts?.length > 0">
          <b-table
            ref="table_contacts"
            class="table-contacts"
            :fields="fields"
            :items="entity.contacts"
            empty-filtered-text="Нет контактов"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner
                  variant="primary"
                  label="Загрузка..."
                />
                <div><strong>Загрузка...</strong></div>
              </div>
            </template>

            <template #cell(actions)="{ item, index }">
              <b-dropdown
                no-caret
                variant="none"
              >
                <template #button-content>
                  <img
                    src="/img/icons/more.svg"
                    alt=""
                  />
                </template>
                <b-dropdown-item @click="setMainContact(item.phone, index)">
                  Назначить контактным лицом
                </b-dropdown-item>
                <b-dropdown-item
                  class="text-red"
                  @click="removeContact(item.phone, index)"
                >
                  Удалить
                </b-dropdown-item>
              </b-dropdown>
              <template v-if="item.main">
                <img
                  class="start_ismain"
                  src="/img/icons/star_primary.svg"
                  alt=""
                />
              </template>
            </template>

            <template #cell(main)="data">
              <template v-if="data.value">
                <img
                  src="/img/icons/star_primary.svg"
                  alt=""
                />
              </template>
            </template>

            <template #empty="scope">
              <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
            </template>
          </b-table>
        </template>
      </div>
      <div class="b-card mt-4 mb-4">
        <div class="b-card__title">Налоги</div>
        <b-row class="mt-2 b-addcontact">
          <b-col cols="3">
            <div class="select-title">Налоговая группа</div>
            <i-select v-model="entity.taxRate.id">
              <i-option value="null">Из товара</i-option>
              <i-option
                v-for="(item, index) of taxRateList"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </i-option>
            </i-select>
          </b-col>

          <b-col cols="3">
            <div class="select-title">Система налогообложения</div>
            <i-select v-model="entity.tax_system">
              <i-option value="null">Не указано</i-option>
              <i-option
                v-for="(item, index) of taxSystemList"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </i-option>
            </i-select>
          </b-col>
        </b-row>
      </div>
      <account
        v-if="entity?.id"
        :entity="entity"
      />
    </b-overlay>
  </b-form>
</template>

<script>
  import Account from '@/views/contractors/components/Account'
  import { EntityModel } from '@/models/entity.model'
  import { ContractModel } from '@/models/contract.model'
  import { AddressModel } from '@/models/address.model'
  import { ContactModel } from '@/models/contact.model'
  import { mapActions } from 'vuex'

  export default {
    components: {
      Account
    },
    props: {
      is_entity: Boolean
    },

    data: () => ({
      add_contact_form: false,
      is_loading: true,
      is_form_entity: false,
      is_form_fact: false,
      is_form_contact: false,
      inn_search_result: false,
      address_entity_search_result: false,
      address_fact_search_result: false,
      add_contact_error: false,
      inn_error: false,
      ogrn_error: false,
      kpp_error: false,
      full_name_error: false,
      name_error: false,
      form_default: '',
      entity: new EntityModel(),
      address_entity: '',
      address_fact: '',
      contact: new ContactModel(),
      taxRateList: [],
      taxRate: null,
      taxSystemList: [
        { name: 'ОСН', value: 'OSN' },
        { name: 'УСН', value: 'USN' },
        { name: 'ЕНВД', value: 'ENVD' }
      ]
    }),

    computed: {
      fields: function () {
        return [
          {
            key: 'actions',
            label: '',
            class: 'td-actions',
            style: 'width: 10%;'
          },
          {
            key: 'name',
            label: 'ФИО',
            sortable: true
          },
          {
            key: 'phone',
            label: 'Номер телефона',
            class: 'w-30'
          },
          {
            key: 'email',
            label: 'E-mail',
            class: 'w-30'
          }
        ]
      }
    },

    apollo: {
      TaxRates: {
        query: require('../../operational-processes/gql/getTaxRates.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.taxRateList = data.TaxRates
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Entity: {
        query: require('../gql/getEntity.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.params.id
          }
        },
        result({ data }) {
          this.entity = new EntityModel({
            ...data?.Entity,
            full_name: data?.Entity.full_name
          })
          this.setTreaty(
            data?.Entity?.supplier_treaty?.map((obj) => {
              const newObj = {
                ...obj,
                ...obj.delivery,
                ...obj.payments?.[0]
              }
              // delete newObj.delivery
              // delete newObj.payments
              return newObj
            }) ?? []
          )
          this.entity = new EntityModel(data?.Entity)
          if (this.entity.address_entity) {
            this.is_form_entity = true
            this.address_entity = this.generateAddressStr(this.entity.address_entity)
          }
          if (this.entity.address_fact) {
            this.is_form_fact = true
            this.address_fact = this.generateAddressStr(this.entity.address_fact)
          }

          this.is_loading = false
        },
        skip() {
          return !this.$route.params.id
        }
      }
    },

    mounted() {
      if (!this.$route.meta.isEdit) this.is_loading = false
      window.addEventListener('click', this.outsideClick)
    },

    destroyed() {
      if (!this.$route.meta.isEdit) this.is_loading = false
      window.removeEventListener('click', this.outsideClick)
    },

    methods: {
      ...mapActions({
        setTreaty: 'contractors/setTreaty'
      }),
      parseEntityAddress() {
        const arrStr = this.address_entity.split(',')
        if (arrStr.length !== 4) {
          this.$noty.error('Ввыедите корректный юридический адрес - Страна, город, улица, дом(через запяую)')
          return false
        }
        if (arrStr[2].toLowerCase().includes('ул ')) {
          arrStr[2] = arrStr[2].slice(3)
        }
        if (arrStr[2].toLowerCase().includes('ул. ')) {
          arrStr[2] = arrStr[2].slice(4)
        }
        if (arrStr[2].toLowerCase().includes('улица ')) {
          arrStr[2] = arrStr[2].slice(6)
        }

        if (arrStr[3].toLowerCase().includes('д ')) {
          arrStr[3] = arrStr[3].slice(2)
        }
        if (arrStr[3].toLowerCase().includes('д. ')) {
          arrStr[3] = arrStr[3].slice(3)
        }
        if (arrStr[3].toLowerCase().includes('дом ')) {
          arrStr[3] = arrStr[3].slice(4)
        }

        this.entity.address_entity.country = arrStr[0].trim()
        this.entity.address_entity.country = arrStr[0].trim()
        this.entity.address_entity.city = arrStr[1].trim()
        this.entity.address_entity.street = arrStr[2].trim()
        this.entity.address_entity.house = arrStr[3].trim()
        return true
      },

      parseFactAddress() {
        const arrStr = this.address_fact.split(',')
        if (arrStr.length !== 4) {
          this.$noty.error('Ввыедите корректный фактический адрес - Страна, город, улица, дом(через запяую)')
          return false
        }
        if (arrStr[2].toLowerCase().includes('ул ')) {
          arrStr[2] = arrStr[2].slice(3)
        }
        if (arrStr[2].toLowerCase().includes('ул. ')) {
          arrStr[2] = arrStr[2].slice(4)
        }
        if (arrStr[2].toLowerCase().includes('улица ')) {
          arrStr[2] = arrStr[2].slice(6)
        }

        if (arrStr[3].toLowerCase().includes('д ')) {
          arrStr[3] = arrStr[3].slice(2)
        }
        if (arrStr[3].toLowerCase().includes('д. ')) {
          arrStr[3] = arrStr[3].slice(3)
        }
        if (arrStr[3].toLowerCase().includes('дом ')) {
          arrStr[3] = arrStr[3].slice(4)
        }

        this.entity.address_fact.country = arrStr[0].trim()
        this.entity.address_fact.country = arrStr[0].trim()
        this.entity.address_fact.city = arrStr[1].trim()
        this.entity.address_fact.street = arrStr[2].trim()
        this.entity.address_fact.house = arrStr[3].trim()
        return true
      },

      outsideClick(e) {
        if (
          !e.target.closest('.search-address') &&
          (this.address_fact_search_result || this.address_entity_search_result)
        ) {
          this.address_fact_search_result = false
          this.address_entity_search_result = false
        }
      },
      formatter_number(value) {
        return value.replace(/[^0-9]/g, '')
      },

      searchEntity() {
        if (this.entity.inn.length > 9) return (this.inn_search_result = true)

        this.inn_search_result = false
      },

      searchAddress(type, e) {
        if (e.key === 'Enter') {
          this[`address_${type}_search_result`] = false
          return
        }
        if (this[`address_${type}`].length > 4) return (this[`address_${type}_search_result`] = true)

        this[`address_${type}_search_result`] = false
      },

      selectInn(item) {
        this.entity = new EntityModel({ ...item, id: this.entity.id })

        this.address_entity = `${item.address_entity.city}, ${item.address_entity.street} ${item.address_entity.house}`
        this.address_entity = this.generateAddressStr({
          country: item.address_entity.country,
          city: item.address_entity.city,
          street: item.address_entity.street,
          house: item.address_entity.house
        })
        if (item.address_entity && !this.is_form_entity) {
          this.toggleForm('entity')
        }
        this.inn_search_result = false
      },

      generateAddressStr(address) {
        const country = address.country ? `${address.country}` : ''
        const city = address.city ? `, ${address.city}` : ''
        const street = address.street ? `, ул. ${address.street}` : ''
        const house = address.house ? `, д. ${address.house}` : ''

        return `${country}${city}${street}${house}`
      },
      setFactAddress(fact) {
        this.entity.address_fact = new AddressModel(fact)
        this.address_fact = this.generateAddressStr(fact)
        this.address_fact_search_result = false
      },
      setAddress(fact) {
        this.entity.address_entity = new AddressModel(fact)
        this.address_entity = this.generateAddressStr(fact)
        this.address_entity_search_result = false
      },
      removeAddress(type) {
        this.toggleForm(type)
      },

      toggleForm(type) {
        this[`is_form_${type}`] = !this[`is_form_${type}`]
      },

      add_contact() {
        if (this.contact.name.length < 1) return (this.add_contact_error = true)

        this.add_contact_error = false
        this.entity.addContact(this.contact)
        this.contact = new ContractModel()
        if (this.contact.main) {
          this.setMainContact(this.contact.phone)
        }
      },

      removeContact(phone, index) {
        this.entity.contacts.map((item) => {
          if (item.phone.includes(phone)) this.entity.contacts.splice(index, 1)
        })
      },

      setMainContact(phone) {
        this.entity.contacts.forEach((item) => (item.main = !!item.phone?.includes(phone)))
      },

      handlerSaveSupplier() {
        if (this.address_entity && !this.parseEntityAddress()) return
        if (this.address_fact && !this.parseFactAddress()) return
        // if (this.entity?.inn?.length < 10) return (this.inn_error = true)
        // if (this.entity?.ogrn?.length < 13) return (this.ogrn_error = true)
        // if (this.entity?.kpp?.length < 9) return (this.kpp_error = true)
        // if (this.entity?.fullname?.length < 4)
        //   return (this.full_name_error = true)
        if (this.entity?.name === undefined || this.entity?.name?.length < 4) return (this.name_error = true)

        this.inn_error = false
        this.ogrn_error = false
        this.kpp_error = false
        this.full_name_error = false
        this.name_error = false

        if (this.entity.address_entity) delete this.entity.address_entity.value
        if (this.entity.address_fact) delete this.entity.address_fact.value

        const form = {
          id: this.$route.meta.isEdit ? this.entity.id : undefined,
          inn: this.entity.inn,
          kpp: this.entity.kpp,
          ogrn: this.entity.ogrn,
          full_name: this.entity.full_name,
          name: this.entity.name,
          contacts: this.entity.contacts,
          address_entity: this.entity.address_entity,
          address_fact: this.entity.address_fact,
          taxRate: this.entity?.taxRate?.id === 'null' ? null : this.entity?.taxRate?.id,
          tax_system: this.entity?.tax_system === 'null' ? null : this.entity?.tax_system
        }

        this.$apollo
          .mutate({
            mutation: require(`../gql/${this.$route.meta.isEdit ? 'update' : 'create'}Supplier.gql`),
            variables: {
              input: {
                ...form,
                select: this.is_entity ? 'ENTITY' : 'SUPPLIER'
              }
            }
          })
          .then(() => {
            this.$router.back()
          })
          .catch((error) => {
            console.error(error.graphQLErrors)
            this.$noty.show(error?.graphQLErrors[0]?.message)
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .custom-control-label {
      padding-top: 1px;
      //  margin-bottom: 0 !important;
      //  line-height: 16px !important;
    }
    .b-card label {
      line-height: 24px;
    }

    .page-contractors {
      button {
        width: 136px;
        justify-content: center;
      }
    }

    .btn-add {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #0066ff;
      margin-bottom: 12px;
    }

    .b-table.table-contacts {
      border: 1px solid #e1e1e1;

      .td-actions {
        width: 5%;
        border-right: none;
      }
    }
  }

  .select-title {
    color: var(--Text-primary---gray700, #313131);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 3px;
  }

  .btns-actions {
    right: 0;
    position: absolute;
    margin-top: -65px;
    margin-bottom: 16px;
  }

  .btn-add {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0066ff;
    margin-bottom: 12px;
  }

  .b-search-result {
    position: absolute;
    z-index: 10;
    top: 75px;
    left: 15px;
    width: 490px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .item {
      padding: 8px 14px;

      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #313131;
      }

      &-info {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        height: 24px;
        overflow: hidden;

        &.street {
          font-weight: 500;
          color: #313131;
          font-size: 14px;
        }

        span {
          margin-right: 12px;

          &.post_index {
            margin-right: 0;
            margin-left: 16px;
          }
        }
      }
    }
  }

  .b-card label {
    line-height: 24px;
  }

  .b-remove-adress {
    margin-top: 30px;
    align-items: center;

    div {
      line-height: 14px;

      img {
        margin-left: 12px;
      }
    }
  }
</style>
