var render = function render(){
  var _vm$entity$account;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-card mt-4 mb-4"
  }, [_c('div', {
    staticClass: "b-card__title"
  }, [_vm._v("Данные счета")]), !_vm.is_form_contact ? [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "btn-add cursor",
    on: {
      "click": _vm.toggleForm
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/contractors/plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить расчетный счет ")])])], 1)] : [_c('b-row', {
    staticClass: "mt-2 b-addcontact"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("БИК")]), _c('b-form-input', {
    class: {
      error: _vm.isErrorBIC
    },
    attrs: {
      "type": "number",
      "placeholder": "БИК"
    },
    on: {
      "input": _vm.inputBik
    },
    model: {
      value: _vm.account.bank.bic,
      callback: function ($$v) {
        _vm.$set(_vm.account.bank, "bic", $$v);
      },
      expression: "account.bank.bic"
    }
  }), _vm.add_contact_error ? _c('div', {
    staticClass: "mt-1 error"
  }, [_vm._v(" * - Необходимо указать бик ")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("Корреспондетский счет")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Введите корр. счет"
    },
    model: {
      value: _vm.account.bank.correspondent_account,
      callback: function ($$v) {
        _vm.$set(_vm.account.bank, "correspondent_account", $$v);
      },
      expression: "account.bank.correspondent_account"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("Рассчетный счет")]), _c('b-form-input', {
    class: {
      error: _vm.isErrorCheckAcc
    },
    attrs: {
      "type": "number",
      "placeholder": "Введите Рассчетный счет"
    },
    on: {
      "input": function ($event) {
        _vm.isErrorCheckAcc = false;
      }
    },
    model: {
      value: _vm.account.check_acc,
      callback: function ($$v) {
        _vm.$set(_vm.account, "check_acc", $$v);
      },
      expression: "account.check_acc"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("Банк")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Введите банк"
    },
    model: {
      value: _vm.account.bank.name,
      callback: function ($$v) {
        _vm.$set(_vm.account.bank, "name", $$v);
      },
      expression: "account.bank.name"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('label', [_vm._v("Комментарий")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Введите комментарий"
    },
    model: {
      value: _vm.account.comment,
      callback: function ($$v) {
        _vm.$set(_vm.account, "comment", $$v);
      },
      expression: "account.comment"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('b-form-checkbox', {
    model: {
      value: _vm.account.main,
      callback: function ($$v) {
        _vm.$set(_vm.account, "main", $$v);
      },
      expression: "account.main"
    }
  }, [_c('span', {}, [_vm._v("Это основной счет")])])], 1)], 1), _c('b-row', {
    staticClass: "mt-2 mb-4"
  }, [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.add_account
    }
  }, [_vm._v(" Добавить счет ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleForm('contact');
      }
    }
  }, [_vm._v(" Отменить ")])], 1)], 1)], ((_vm$entity$account = _vm.entity.account) === null || _vm$entity$account === void 0 ? void 0 : _vm$entity$account.length) > 0 ? [_c('b-table', {
    ref: "table_contacts",
    staticClass: "table-contacts",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.entity.account,
      "responsive": "",
      "empty-filtered-text": "Нет контактов"
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "cell(actions)",
      fn: function (_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('b-dropdown', {
          attrs: {
            "no-caret": "",
            "variant": "none"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('img', {
                attrs: {
                  "src": "/img/icons/more.svg",
                  "alt": ""
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.setMainAccount(item.id, index);
            }
          }
        }, [_vm._v(" Сделать счет основным ")]), _c('b-dropdown-item', {
          staticClass: "text-red",
          on: {
            "click": function ($event) {
              return _vm.removeAccount(item.id, index);
            }
          }
        }, [_vm._v(" Удалить ")])], 1), item.main ? [_c('img', {
          staticClass: "start_ismain",
          attrs: {
            "src": "/img/icons/star_primary.svg",
            "alt": ""
          }
        })] : _vm._e()], 2)];
      }
    }, {
      key: "cell(main)",
      fn: function (data) {
        return [data.value ? [_c('img', {
          attrs: {
            "src": "/img/icons/star_primary.svg",
            "alt": ""
          }
        })] : _vm._e()];
      }
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }], null, false, 315351524)
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }