<template>
  <div class="b-card mt-4 mb-4">
    <div class="b-card__title">Данные счета</div>
    <template v-if="!is_form_contact">
      <b-row>
        <b-col>
          <div
            class="btn-add cursor"
            @click="toggleForm"
          >
            <img
              src="/img/icons/contractors/plus.svg"
              class="mr-2"
              alt=""
            />
            Добавить расчетный счет
          </div>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="mt-2 b-addcontact">
        <b-col cols="4">
          <label>БИК</label>
          <b-form-input
            v-model="account.bank.bic"
            :class="{ error: isErrorBIC }"
            type="number"
            placeholder="БИК"
            @input="inputBik"
          />
          <div
            v-if="add_contact_error"
            class="mt-1 error"
          >
            * - Необходимо указать бик
          </div>
        </b-col>
        <b-col cols="4">
          <label>Корреспондетский счет</label>
          <b-form-input
            v-model="account.bank.correspondent_account"
            type="number"
            placeholder="Введите корр. счет"
          />
        </b-col>
        <b-col cols="4">
          <label>Рассчетный счет</label>
          <b-form-input
            v-model="account.check_acc"
            type="number"
            :class="{ error: isErrorCheckAcc }"
            placeholder="Введите Рассчетный счет"
            @input="isErrorCheckAcc = false"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4">
          <label>Банк</label>
          <b-form-input
            v-model="account.bank.name"
            type="text"
            placeholder="Введите банк"
          />
        </b-col>
        <b-col cols="8">
          <label>Комментарий</label>
          <b-form-input
            v-model="account.comment"
            type="text"
            placeholder="Введите комментарий"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-checkbox v-model="account.main">
            <span class="">Это основной счет</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-4">
        <b-col>
          <b-button
            variant="primary"
            @click="add_account"
          >
            Добавить счет
          </b-button>
          <b-button
            class="ml-2"
            variant="outline-primary"
            @click="toggleForm('contact')"
          >
            Отменить
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template v-if="entity.account?.length > 0">
      <b-table
        ref="table_contacts"
        class="table-contacts"
        :fields="fields"
        :items="entity.account"
        responsive
        empty-filtered-text="Нет контактов"
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner
              variant="primary"
              label="Загрузка..."
            />
            <div><strong>Загрузка...</strong></div>
          </div>
        </template>

        <template #cell(actions)="{ item, index }">
          <div style="position: relative">
            <b-dropdown
              no-caret
              variant="none"
            >
              <template #button-content>
                <img
                  src="/img/icons/more.svg"
                  alt=""
                />
              </template>
              <b-dropdown-item @click="setMainAccount(item.id, index)"> Сделать счет основным </b-dropdown-item>
              <b-dropdown-item
                class="text-red"
                @click="removeAccount(item.id, index)"
              >
                Удалить
              </b-dropdown-item>
            </b-dropdown>
            <template v-if="item.main">
              <img
                class="start_ismain"
                src="/img/icons/star_primary.svg"
                alt=""
              />
            </template>
          </div>
        </template>

        <template #cell(main)="data">
          <template v-if="data.value">
            <img
              src="/img/icons/star_primary.svg"
              alt=""
            />
          </template>
        </template>

        <template #empty="scope">
          <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
  import { EntityModel } from '@/models/entity.model'

  export default {
    name: 'Account',
    props: {
      entity: EntityModel,
      default: () => new EntityModel()
    },
    data() {
      return {
        isErrorBIC: false,
        isErrorCheckAcc: false,
        is_form_contact: false,
        account: {
          bank: {
            bic: '',
            name: '',
            correspondent_account: '',
            address_fact: null
          },
          comment: '',
          check_acc: '',
          main: true
        },
        add_contact_error: false,
        accounts: [],
        fields: [
          {
            key: 'actions',
            label: '',
            thStyle: 'width:70px'
          },
          {
            key: 'bank.bic',
            label: 'БИК'
          },
          {
            key: 'bank.correspondent_account',
            label: 'Корреспондетский счет'
          },
          {
            key: 'check_acc',
            label: 'Рассчетный счет'
          },
          {
            key: 'bank.name',
            label: 'Банк'
          },
          {
            key: 'comment',
            label: 'Комментарий'
          }
        ]
      }
    },
    methods: {
      toggleForm() {
        this.is_form_contact = !this.is_form_contact
      },
      async add_account() {
        if (!this.entity?.id) return
        if (!this.account?.check_acc && !this.account?.bank.bic) {
          this.$noty.error('Вы не добавили БИК банка и расчетный счет')
          this.isErrorBIC = true
          this.isErrorCheckAcc = true
          return
        }
        if (!this.account?.bank.bic) {
          this.$noty.error('Вы не добавили БИК банка')
          this.isErrorBIC = true
          return
        }
        if (!this.account?.check_acc) {
          this.isErrorCheckAcc = true
          this.$noty.error('Вы не добавили расчетный счет')
          return
        }
        this.isErrorBIC = false
        this.isErrorCheckAcc = false
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/createAccount.gql'),
          variables: {
            input: { ...this.account, entity: this.entity.id }
          }
        })

        this.entity.addAccount({
          id: data.CreateCompanyBankAccount.id,
          ...this.account
        })
        if (this.account.main) this.entity.setMainAccount(data.CreateCompanyBankAccount.id)
        this.is_form_contact = false
        this.account = {
          bank: {
            bic: '',
            name: '',
            correspondent_account: '',
            address_fact: null
          },
          comment: '',
          check_acc: '',
          main: true
        }
      },
      async setMainAccount(id) {
        this.entity.setMainAccount(id)
        await this.$apollo.mutate({
          mutation: require('../gql/UpdateAccount.gql'),
          variables: {
            input: { id: id, main: true }
          }
        })
      },
      async removeAccount(id) {
        await this.$apollo.mutate({
          mutation: require('../gql/RemoveAccount.gql'),
          variables: { ids: [id] }
        })
        this.entity.removeAccount(id)
      },
      async inputBik(bik) {
        this.isErrorBIC = false
        if (bik.length !== 9) return
        const { data } = await this.$apollo.query({
          query: require('../gql/DadataBank.graphql'),
          variables: { bik: bik }
        })

        this.account.bank.bic = bik
        this.account.bank.correspondent_account = data.DadataBank?.correspondent_account
        this.account.bank.name = data.DadataBank?.name
        this.account.bank.address_fact = data.DadataBank?.address
      }
    }
  }
</script>

<style scoped>
  .error {
    background: #fffafa;
    border: 1px solid #e53835;
  }
  img.start_ismain {
    position: absolute;
    top: 50%;
    right: -1px;
    transform: translateY(-50%);
  }
</style>
