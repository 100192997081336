var render = function render(){
  var _vm$entity$contacts, _vm$entity;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handlerSaveSupplier.apply(null, arguments);
      }
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.is_loading,
      "rounded": "",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('div', {
    staticClass: "btns-actions"
  }, [_c('b-button', {
    staticClass: "ml-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handlerSaveSupplier
    }
  }, [_vm._v(" Сохранить ")])], 1), _c('div', {
    staticClass: "b-card",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c('div', {
    staticClass: "b-card__title"
  }, [_vm._v("Основные данные")]), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("ИНН")]), _c('b-form-input', {
    class: {
      error: _vm.inn_error
    },
    attrs: {
      "formatter": _vm.formatter_number,
      "type": "number",
      "min-length": "10",
      "max-length": "12"
    },
    on: {
      "keyup": _vm.searchEntity
    },
    model: {
      value: _vm.entity.inn,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "inn", $$v);
      },
      expression: "entity.inn"
    }
  }), _vm.inn_search_result ? [_c('apollo-query', {
    attrs: {
      "query": require("../gql/getEntityDadata.gql"),
      "variables": {
        inn: _vm.entity.inn
      },
      "skip": _vm.entity.inn < 10,
      "fetch-policy": "no-cache"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _ref$result = _ref.result,
            error = _ref$result.error,
            data = _ref$result.data,
            isLoading = _ref.isLoading;
        return [isLoading ? _c('div', {
          staticClass: "b-search-result p-2 center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1) : error ? _c('div', {
          staticClass: "error apollo"
        }, [_vm._v(" Ошибка при запросе ")]) : data ? _c('div', {
          staticClass: "b-search-result"
        }, _vm._l(data.Dadata, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item cursor",
            on: {
              "click": function ($event) {
                return _vm.selectInn(item);
              }
            }
          }, [_c('div', {
            staticClass: "item-name"
          }, [_vm._v(_vm._s(item.name))]), _c('div', {
            staticClass: "item-info"
          }, [_vm._v(" ИНН: " + _vm._s(item.inn) + " КПП: " + _vm._s(item.kpp) + " " + _vm._s(item.address_entity.city) + ", " + _vm._s(item.address_entity.country) + ", " + _vm._s(item.address_entity.post_index) + ", улица " + _vm._s(item.address_entity.street) + ", " + _vm._s(item.address_entity.apartment) + " ")])]);
        }), 0) : _c('div', {
          staticClass: "b-search-result p-3 center"
        }, [_vm._v(" Нет результата ")])];
      }
    }], null, false, 708167889)
  })] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("КПП")]), _c('b-form-input', {
    class: {
      error: _vm.kpp_error
    },
    attrs: {
      "formatter": _vm.formatter_number,
      "type": "text",
      "maxlength": "9",
      "required": ""
    },
    model: {
      value: _vm.entity.kpp,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "kpp", $$v);
      },
      expression: "entity.kpp"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("ОГРН")]), _c('b-form-input', {
    class: {
      error: _vm.ogrn_error
    },
    attrs: {
      "type": "number",
      "pattern": "[0-9]{13,15}"
    },
    model: {
      value: _vm.entity.ogrn,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "ogrn", $$v);
      },
      expression: "entity.ogrn"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', [_vm._v("Полное наименование")]), _c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.entity.full_name,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "full_name", $$v);
      },
      expression: "entity.full_name"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', {
    class: {
      error: _vm.name_error
    }
  }, [_vm._v("Краткое наименование")]), _c('b-form-input', {
    class: {
      error: _vm.name_error
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.entity.name,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "name", $$v);
      },
      expression: "entity.name"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "b-card mt-4"
  }, [_c('div', {
    staticClass: "b-card__title"
  }, [_vm._v("Юридический адрес")]), !_vm.is_form_entity ? [_c('div', {
    staticClass: "btn-add cursor",
    on: {
      "click": function ($event) {
        return _vm.toggleForm('entity');
      }
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/contractors/plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить адрес ")])] : [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', [_vm._v("Страна, город, улица, дом")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Введите адрес"
    },
    on: {
      "keyup": function (e) {
        return _vm.searchAddress('entity', e);
      }
    },
    model: {
      value: _vm.address_entity,
      callback: function ($$v) {
        _vm.address_entity = $$v;
      },
      expression: "address_entity"
    }
  }), _vm.address_entity_search_result ? [_c('apollo-query', {
    attrs: {
      "query": require("../gql/getAddressDadata.gql"),
      "variables": {
        value: _vm.address_entity
      },
      "skip": _vm.address_entity < 5,
      "fetch-policy": "no-cache"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var _ref2$result = _ref2.result,
            error = _ref2$result.error,
            data = _ref2$result.data,
            isLoading = _ref2.isLoading;
        return [isLoading ? _c('div', {
          staticClass: "b-search-result p-2 center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1) : error ? _c('div', {
          staticClass: "error apollo"
        }, [_vm._v(" Ошибка при запросе ")]) : data ? _c('div', {
          staticClass: "b-search-result"
        }, _vm._l(data.DadataAddress, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item cursor",
            on: {
              "click": function ($event) {
                return _vm.setAddress(item);
              }
            }
          }, [_c('div', {
            staticClass: "item-info street"
          }, [_vm._v(" улица " + _vm._s(item.street) + " " + _vm._s(item.house ? `, ${item.house}` : '') + " ")]), _c('div', {
            staticClass: "item-info"
          }, [_vm._v(" " + _vm._s(item.country) + ", г. " + _vm._s(item.city) + " "), _c('span', {
            staticClass: "post_index"
          }, [_vm._v(" Индекс: " + _vm._s(item.post_index))])])]);
        }), 0) : _c('div', {
          staticClass: "b-search-result p-3 center"
        }, [_vm._v(" Нет результата ")])];
      }
    }], null, false, 3122038492)
  })] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('label', [_vm._v("Квартира/Офис")]), _c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.entity.address_entity.apartment,
      callback: function ($$v) {
        _vm.$set(_vm.entity.address_entity, "apartment", $$v);
      },
      expression: "entity.address_entity.apartment"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('label', [_vm._v("Почтовый индекс")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "000000"
    },
    model: {
      value: _vm.entity.address_entity.post_index,
      callback: function ($$v) {
        _vm.$set(_vm.entity.address_entity, "post_index", $$v);
      },
      expression: "entity.address_entity.post_index"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end b-remove-adress",
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticClass: "cursor",
    on: {
      "click": function ($event) {
        return _vm.removeAddress('entity');
      }
    }
  }, [_vm._v(" Удалить адрес "), _c('img', {
    attrs: {
      "src": "/img/icons/trash.svg",
      "alt": "Удалить адрес"
    }
  })])])], 1)]], 2), _c('div', {
    staticClass: "b-card mt-4"
  }, [_c('div', {
    staticClass: "b-card__title"
  }, [_vm._v("Физический адрес")]), !_vm.is_form_fact ? [_c('div', {
    staticClass: "btn-add cursor",
    on: {
      "click": function ($event) {
        return _vm.toggleForm('fact');
      }
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/contractors/plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить адрес ")])] : [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', [_vm._v("Страна, город, улица, дом")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Введите адрес"
    },
    on: {
      "keyup": function (e) {
        return _vm.searchAddress('fact', e);
      }
    },
    model: {
      value: _vm.address_fact,
      callback: function ($$v) {
        _vm.address_fact = $$v;
      },
      expression: "address_fact"
    }
  }), _vm.address_fact_search_result ? [_c('apollo-query', {
    attrs: {
      "query": require("../gql/getAddressDadata.gql"),
      "variables": {
        value: _vm.address_fact
      },
      "skip": _vm.address_fact < 5,
      "fetch-policy": "no-cache"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var _ref3$result = _ref3.result,
            error = _ref3$result.error,
            data = _ref3$result.data,
            isLoading = _ref3.isLoading;
        return [isLoading ? _c('div', {
          staticClass: "b-search-result p-2 center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1) : error ? _c('div', {
          staticClass: "error apollo"
        }, [_vm._v(" Ошибка при запросе ")]) : data ? _c('div', {
          staticClass: "b-search-result"
        }, _vm._l(data.DadataAddress, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item cursor",
            on: {
              "click": function ($event) {
                return _vm.setFactAddress(item);
              }
            }
          }, [_c('div', {
            staticClass: "item-info street"
          }, [_vm._v(" улица " + _vm._s(item.street) + " " + _vm._s(item.house ? `, ${item.house}` : '') + " ")]), _c('div', {
            staticClass: "item-info"
          }, [_vm._v(" " + _vm._s(item.country) + ", " + _vm._s(item.city) + " "), _c('span', {
            staticClass: "post_index"
          }, [_vm._v(" Индекс: " + _vm._s(item.post_index) + " ")])])]);
        }), 0) : _c('div', {
          staticClass: "b-search-result p-3 center"
        }, [_vm._v(" Нет результата ")])];
      }
    }], null, false, 880060314)
  })] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('label', [_vm._v("Квартира/Офис")]), _c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.entity.address_fact.apartment,
      callback: function ($$v) {
        _vm.$set(_vm.entity.address_fact, "apartment", $$v);
      },
      expression: "entity.address_fact.apartment"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('label', [_vm._v("Почтовый индекс")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "000000"
    },
    model: {
      value: _vm.entity.address_fact.post_index,
      callback: function ($$v) {
        _vm.$set(_vm.entity.address_fact, "post_index", $$v);
      },
      expression: "entity.address_fact.post_index"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end b-remove-adress",
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticClass: "cursor",
    on: {
      "click": function ($event) {
        return _vm.removeAddress('fact');
      }
    }
  }, [_vm._v(" Удалить адрес "), _c('img', {
    attrs: {
      "src": "/img/icons/trash.svg",
      "alt": "Удалить адрес"
    }
  })])])], 1)]], 2), _c('div', {
    staticClass: "b-card mt-4 mb-4"
  }, [_c('div', {
    staticClass: "b-card__title"
  }, [_vm._v("Контакты сотрудников")]), !_vm.is_form_contact ? [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "btn-add cursor",
    on: {
      "click": function ($event) {
        return _vm.toggleForm('contact');
      }
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/contractors/plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить контакт ")])])], 1)] : [_c('b-row', {
    staticClass: "mt-2 b-addcontact"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("ФИО")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "ФИО"
    },
    model: {
      value: _vm.contact.name,
      callback: function ($$v) {
        _vm.$set(_vm.contact, "name", $$v);
      },
      expression: "contact.name"
    }
  }), _vm.add_contact_error ? _c('div', {
    staticClass: "mt-1 error"
  }, [_vm._v(" * - Необходимо указать имя ")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("Номер телефона")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Введите номер телефона"
    },
    model: {
      value: _vm.contact.phone,
      callback: function ($$v) {
        _vm.$set(_vm.contact, "phone", $$v);
      },
      expression: "contact.phone"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', [_vm._v("E-mail")]), _c('b-form-input', {
    attrs: {
      "type": "email",
      "placeholder": "Введите email"
    },
    model: {
      value: _vm.contact.email,
      callback: function ($$v) {
        _vm.$set(_vm.contact, "email", $$v);
      },
      expression: "contact.email"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('b-form-checkbox', {
    staticClass: "align-items-center d-flex",
    model: {
      value: _vm.contact.main,
      callback: function ($$v) {
        _vm.$set(_vm.contact, "main", $$v);
      },
      expression: "contact.main"
    }
  }, [_c('span', {}, [_vm._v("Это контактное лицо")])])], 1)], 1), _c('b-row', {
    staticClass: "mt-2 mb-4"
  }, [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.add_contact
    }
  }, [_vm._v(" Добавить контакт ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleForm('contact');
      }
    }
  }, [_vm._v(" Отменить ")])], 1)], 1)], ((_vm$entity$contacts = _vm.entity.contacts) === null || _vm$entity$contacts === void 0 ? void 0 : _vm$entity$contacts.length) > 0 ? [_c('b-table', {
    ref: "table_contacts",
    staticClass: "table-contacts",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.entity.contacts,
      "empty-filtered-text": "Нет контактов"
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "cell(actions)",
      fn: function (_ref4) {
        var item = _ref4.item,
            index = _ref4.index;
        return [_c('b-dropdown', {
          attrs: {
            "no-caret": "",
            "variant": "none"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('img', {
                attrs: {
                  "src": "/img/icons/more.svg",
                  "alt": ""
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.setMainContact(item.phone, index);
            }
          }
        }, [_vm._v(" Назначить контактным лицом ")]), _c('b-dropdown-item', {
          staticClass: "text-red",
          on: {
            "click": function ($event) {
              return _vm.removeContact(item.phone, index);
            }
          }
        }, [_vm._v(" Удалить ")])], 1), item.main ? [_c('img', {
          staticClass: "start_ismain",
          attrs: {
            "src": "/img/icons/star_primary.svg",
            "alt": ""
          }
        })] : _vm._e()];
      }
    }, {
      key: "cell(main)",
      fn: function (data) {
        return [data.value ? [_c('img', {
          attrs: {
            "src": "/img/icons/star_primary.svg",
            "alt": ""
          }
        })] : _vm._e()];
      }
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }], null, false, 4095427280)
  })] : _vm._e()], 2), _c('div', {
    staticClass: "b-card mt-4 mb-4"
  }, [_c('div', {
    staticClass: "b-card__title"
  }, [_vm._v("Налоги")]), _c('b-row', {
    staticClass: "mt-2 b-addcontact"
  }, [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "select-title"
  }, [_vm._v("Налоговая группа")]), _c('i-select', {
    model: {
      value: _vm.entity.taxRate.id,
      callback: function ($$v) {
        _vm.$set(_vm.entity.taxRate, "id", $$v);
      },
      expression: "entity.taxRate.id"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "null"
    }
  }, [_vm._v("Из товара")]), _vm._l(_vm.taxRateList, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "select-title"
  }, [_vm._v("Система налогообложения")]), _c('i-select', {
    model: {
      value: _vm.entity.tax_system,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "tax_system", $$v);
      },
      expression: "entity.tax_system"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "null"
    }
  }, [_vm._v("Не указано")]), _vm._l(_vm.taxSystemList, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)], 1)], 1)], 1), (_vm$entity = _vm.entity) !== null && _vm$entity !== void 0 && _vm$entity.id ? _c('account', {
    attrs: {
      "entity": _vm.entity
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }